import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"


import UtilityStoryitem from '../components/utilityStoryitem.js'

// Conditional syntax in JSX
// https://stackoverflow.com/questions/53508119/what-is-the-syntax-for-a-simple-if-conditional-in-gatsby-js


const TermsPage = ({data}) => {

  return (
    <Layout>
      <SEO title="Brukerbetinbelser | Gratis.no" description={""}/>

      <div>
        <section className="section novi-bg novi-bg-img section-lg bg-white section-top-shadow">
          <div className="container">
            <h2 style={{marginBottom:'30px'}} className="text-center text-sm-left">Betingelser <span className="h2-style">for bruk</span></h2>

            <p className="infoPageParagraph">
              Gratis.no er en oversikt med utelukkende annonser/kommersielt innhold tilbudt av tredjeparter via internett. Vi inkluderer tilbud
              og tjenester som redaktørene mener er spesielt gunstige, eller som på annen måte passer inn på denne siden.
              </p>
            <p className="infoPageParagraph">
              Alle spørsmål knyttet til tjenester og produkter funnet i våre oversikter bør derfor rettes de respektive tjeneste- og produkttilbydere.
            </p>
            <p className="infoPageParagraph">
              Innholdet på sidene våre er basert på ulike kilder; alt fra tips fra besøkende til bidrag fra samarbeidspartnere. Alt innholdet bør
              imidlertid betraktes som kommersielt annonseinnhold.
            </p>
            <p className="infoPageParagraph">
              Alle lesere og brukere av gratis.no,inkludert tilknyttede konti i sosiale medier, må godta at alt innhold
              skal leses og vurderes som annonser og/eller reklame for eksterne aktører.
            </p>
            <p className="infoPageParagraph">
              De fleste tilbud på gratis.no har vilkår og betingelser det er viktig at du setter deg inn i på egenhånd. Merk spesielt at informasjon om tilbud som
              presenteres på sidene våre kan inneholde feil eller være utdatert - og vi understreker derfor at det er svært viktig å sette
              seg inn i den enkelte tilbyders vilkår og betingelser på egenhånd.
              </p>
            <p className="infoPageParagraph">
              I tillegg krever vi at at ingen
              av våre besøkende bestiller tjenester eller produkter de har funnet informasjon om på mine nettsider, uten å være over 18 år eller har
              samtykke fra foresatte.
            </p>
            <p className="infoPageParagraph">
              Vi tar sterk avstand fra å bestille tjenester eller produkter i andres navn uten deres samtykke, og informasjon funnet på gratis.no
              skal ikke brukes til dette formålet!
            </p>

            <ul className="infoPageList">
              <li><Link to="/personvern/">Vår personvernserklæring</Link></li>
            </ul>

            <div className="mt-xl-40 mt-50 text-center text-sm-right"><Link
              className="button button-icon button-icon-right button-black" to="/"><span
              className="icon novi-icon fa fa-chevron-right"/>Til forsiden</Link></div>
          </div>
        </section>

      </div>
    </Layout>
  )
}

export default TermsPage


